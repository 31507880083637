<template>
  <my-farme :width="1920" :height="1080" bgColor="#eee">
    <div class="container">
      <div class="header">
        <HeaderInfo />
      </div>
      <div class="content">
        <div class="container">
          <div class="leftCon">
            <!-- 设备管理 -->
            <div class="topCon"></div>
            <!-- 报事报修 -->
            <div class="bodyCon"></div>
            <!-- 消防管理 -->
            <div class="footerCon"></div>
          </div>
          <div class="cCon">
            <div class="topCon">
              <!-- 幸福佳苑社区背景 -->
              <!-- 幸福佳苑今日报警数据统计 -->
              <div class="data-statistics"></div>
            </div>
            <!-- 报警中心 -->
            <div class="footerCon">
              <AlarmCenter></AlarmCenter>
            </div>
          </div>
          <div class="rightCon">
            <!-- 实时监控 -->
            <div class="topCon"></div>
            <!-- 电梯健康安全指数 -->
            <div class="bodyCon"></div>
            <!-- 7日告警统计 -->
            <div class="footerCon"></div>
          </div>
        </div>
      </div>
      <div class="footer">版权所有：湖北三三建维科技有限公司</div>
    </div>
  </my-farme>
</template>

<script>
import HeaderInfo from "./components/HeaderInfo.vue";
import AlarmCenter from "./details/AlarmCenter.vue";

export default {
  name: "",
  components: {
    HeaderInfo,
    AlarmCenter,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss'  scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../../assets/images/index/bg_img1.png) no-repeat;
  .header {
    color: #fff;
    width: 100%;
    height: 61px;
    border-bottom: 4px solid rgba(255, 255, 255, 0.3);
  }
  .content {
    width: 100%;
    height: 977px;
    color: #fff;
    padding: 19px 30px;
    .container {
      display: flex;
      justify-content: space-between;
      .leftCon {
        width: 540px;

        //安防监控
        .topCon {
          width: 540px;
          height: 404px;
          background: url(../../assets/images/indexCon/index1_kuang1.png)
            no-repeat;
          margin-bottom: 16px;
        }
        //消防报警
        .bodyCon {
          width: 540px;
          height: 304px;
          background: url(../../assets/images/indexCon/index1_kuang2.png)
            no-repeat;
          margin-bottom: 16px;
        }
        //报事报修
        .footerCon {
          width: 540px;
          height: 204px;
          background: url(../../assets/images/indexCon/index1_kuang3.png)
            no-repeat;
        }
      }

      .cCon {
        flex: 1;
        position: relative;
        .topCon {
          position: relative;
          width: 100%;
          height: 530px;
          padding: 20px;
          //数据统计
          .data-statistics {
            display: flex;
            justify-content: space-evenly;
            position: absolute;
            left: 0;
            top: 20px;
            width: 100%;
            height: 140px;
            padding-left: 70px;
            padding-right: 70px;
            padding-top: 20px;
            background: rgba(0, 0, 0, 0.3);
            .item {
              position: relative;
              width: 100px;
              height: 100px;
              .itemCon {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 27px;
                font-family: Impact;
                color: #ffffff;
                padding: 10px 22px 10px 22px;
                .itemCon-top {
                  text-align: center;
                  margin-bottom: 20px;
                }
                .itemCon-bottom {
                  font-size: 14px;
                  text-align: center;
                  color: #00fcfc;
                  font-weight: 700;
                }
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
            .item2 {
              position: relative;
              width: 120px;
              height: 120px;
              .itemCon {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 27px;
                font-family: Impact;
                color: #ffffff;
                padding: 18px 30px 10px 30px;
                .itemCon-top {
                  text-align: center;
                  margin-bottom: 30px;
                }
                .itemCon-bottom {
                  font-size: 14px;
                  text-align: center;
                  color: #00fcfc;
                }
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        //报警中心
        .footerCon {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 740px;
          height: 404px;
          margin-left: 20px;
          background: url(../../assets/images/indexCon/index1_kuang4.png)
            no-repeat;
        }
      }

      .rightCon {
        width: 540px;

        .topCon {
          width: 540px;
          height: 404px;
          background: url(../../assets/images/indexCon/index1_kuang1.png)
            no-repeat;
          margin-bottom: 16px;
        }

        .bodyCon {
          width: 540px;
          height: 304px;
          background: url(../../assets/images/indexCon/index1_kuang2.png)
            no-repeat;
          margin-bottom: 16px;
        }

        .footerCon {
          width: 540px;
          height: 204px;
          background: url(../../assets/images/indexCon/index1_kuang3.png)
            no-repeat;
        }
      }
    }
  }
  .footer {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    width: 100%;
    height: 15px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
  }
}
</style>

<template>
  <div class="childPage">
    <div class="topTitle">报警中心</div>
    <div class="concent">
      <div class="topCon">
        <div class="item item1" v-for="(item,index) in 8" :key="index">
          <div class="img">
            <img
              src="https://cdn.jsdelivr.net/gh/cdn003/cdn2@1.0/images/mn/t01c940d30f150d298a.jpg"
              alt=""
            />
          </div>
          <div class="topTxt">
            <img
              src="@/assets/images/indexCon/index_alarm_red.png"
              alt=""
            />
            <span>严重告警</span>
          </div>
          <div class="bottomTxt">电动车进电梯告警</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang='scss'  scoped>
.childPage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 0 14px 18px;
  .topTitle {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    width: 264px;
    height: 31px;
    font-size: 16px;
    text-align: center;
    padding-top: 9px;
  }
  .concent {
    width: 100%;
    margin-top: 30px;
    .topCon {
      width: 100%;
      .item {
        float: left;
        height: 175px;
        margin-right: 10px;
        background-color: #133435;
        .img {
          width: 170px;
          height: 120px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .topTxt {
          width: 100%;
          color: #f7517f;
          font-size: 12px;
          margin-top: 8px;
          margin-bottom: 8px;
          img {
            margin-right: 6px;
          }
        }
        .bottomTxt {
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
